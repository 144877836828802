exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-akshaypatel-js": () => import("./../../../src/pages/akshaypatel.js" /* webpackChunkName: "component---src-pages-akshaypatel-js" */),
  "component---src-pages-app-development-company-js": () => import("./../../../src/pages/app-development-company.js" /* webpackChunkName: "component---src-pages-app-development-company-js" */),
  "component---src-pages-bansipipaliya-js": () => import("./../../../src/pages/bansipipaliya.js" /* webpackChunkName: "component---src-pages-bansipipaliya-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-case-study-two-js": () => import("./../../../src/pages/case-study-two.js" /* webpackChunkName: "component---src-pages-case-study-two-js" */),
  "component---src-pages-client-testimonials-js": () => import("./../../../src/pages/client-testimonials.js" /* webpackChunkName: "component---src-pages-client-testimonials-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-diwali-js": () => import("./../../../src/pages/diwali.js" /* webpackChunkName: "component---src-pages-diwali-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-game-development-company-js": () => import("./../../../src/pages/game-development-company.js" /* webpackChunkName: "component---src-pages-game-development-company-js" */),
  "component---src-pages-game-landing-js": () => import("./../../../src/pages/game-landing.js" /* webpackChunkName: "component---src-pages-game-landing-js" */),
  "component---src-pages-game-landing-thankyou-js": () => import("./../../../src/pages/game-landing/thankyou.js" /* webpackChunkName: "component---src-pages-game-landing-thankyou-js" */),
  "component---src-pages-hardiklalpurwala-js": () => import("./../../../src/pages/hardiklalpurwala.js" /* webpackChunkName: "component---src-pages-hardiklalpurwala-js" */),
  "component---src-pages-hire-offshore-developers-js": () => import("./../../../src/pages/hire-offshore-developers.js" /* webpackChunkName: "component---src-pages-hire-offshore-developers-js" */),
  "component---src-pages-html-sitemap-js": () => import("./../../../src/pages/html-sitemap.js" /* webpackChunkName: "component---src-pages-html-sitemap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-jaydeepsanghani-js": () => import("./../../../src/pages/jaydeepsanghani.js" /* webpackChunkName: "component---src-pages-jaydeepsanghani-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-nft-development-company-js": () => import("./../../../src/pages/nft-development-company.js" /* webpackChunkName: "component---src-pages-nft-development-company-js" */),
  "component---src-pages-our-technology-js": () => import("./../../../src/pages/our-technology.js" /* webpackChunkName: "component---src-pages-our-technology-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-quotation-js": () => import("./../../../src/pages/quotation.js" /* webpackChunkName: "component---src-pages-quotation-js" */),
  "component---src-pages-rahulgoti-js": () => import("./../../../src/pages/rahulgoti.js" /* webpackChunkName: "component---src-pages-rahulgoti-js" */),
  "component---src-pages-rajkumarmjain-js": () => import("./../../../src/pages/rajkumarmjain.js" /* webpackChunkName: "component---src-pages-rajkumarmjain-js" */),
  "component---src-pages-rashmin-js": () => import("./../../../src/pages/rashmin.js" /* webpackChunkName: "component---src-pages-rashmin-js" */),
  "component---src-pages-resource-info-js": () => import("./../../../src/pages/resource-info.js" /* webpackChunkName: "component---src-pages-resource-info-js" */),
  "component---src-pages-ronakpipaliya-js": () => import("./../../../src/pages/ronakpipaliya.js" /* webpackChunkName: "component---src-pages-ronakpipaliya-js" */),
  "component---src-pages-somishkakadiya-js": () => import("./../../../src/pages/somishkakadiya.js" /* webpackChunkName: "component---src-pages-somishkakadiya-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-vimaltarsariya-js": () => import("./../../../src/pages/vimaltarsariya.js" /* webpackChunkName: "component---src-pages-vimaltarsariya-js" */),
  "component---src-pages-web-development-company-js": () => import("./../../../src/pages/web-development-company.js" /* webpackChunkName: "component---src-pages-web-development-company-js" */),
  "component---src-pages-why-vasundhara-js": () => import("./../../../src/pages/why-vasundhara.js" /* webpackChunkName: "component---src-pages-why-vasundhara-js" */)
}

