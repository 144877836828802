import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./node_modules/bootstrap/dist/js/bootstrap.min.js";


import 'react-toastify/dist/ReactToastify.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-pagination-bar/dist/index.css';

import "./src/css/style.css";
import "./src/css/history.css"
import './src/css/anim.css';
import './src/css/slickslider.css'
import './src/css/ronakpipaliya.css'
import './src/css/diwali.css'

import "aos/dist/aos.css";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

var s = document.createElement("script");
s.type = "text/javascript";
s.src = "https://code.jquery.com/jquery-3.6.0.min.js";
document.querySelector("body").append(s);

var s1 = document.createElement("script");
s1.type = "text/javascript";
s1.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js";
document.querySelector("body").append(s1);

var s2 = document.createElement("script");
s2.type = "text/javascript";
s2.src = "https://cdnjs.cloudflare.com/ajax/libs/animejs/2.0.2/anime.min.js";
document.querySelector("body").append(s2);

var s3 = document.createElement("script");
s3.type = "text/javascript";
s3.src = "./js/diwali.js";
document.querySelector("body").append(s3);


document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelectorAll(".img-div a")) {
        setTimeout(() => {

        }, 2500)
    }
});